import { BehaviorSubject, Observable, Subject, Subscription, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GasSupplyEmergencyData } from '../modules/overview/models/gas-supply-emergency-data.model';

export class GasSupplyEmergencyState {
  private readonly _isLoadingSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private readonly _isLoadingSubscription?: Subscription;

  private readonly _gasSupplyEmergencyDataSubject$: BehaviorSubject<
    GasSupplyEmergencyData | undefined
  > = new BehaviorSubject<GasSupplyEmergencyData | undefined>(undefined);
  private readonly _gasSupplyEmergencyDataSubscription?: Subscription;

  constructor(private readonly _ngOnDestroySubject$: Subject<void>) {
    this._isLoadingSubscription = this._isLoadingSubject$
      .pipe(
        tap((isLoading: boolean) => (this._isLoading = isLoading)),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();

    this._gasSupplyEmergencyDataSubject$
      .pipe(
        tap(
          (gasSupplyEmergencyData: GasSupplyEmergencyData) =>
            (this._gasSupplyEmergencyData = gasSupplyEmergencyData)
        ),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();
  }

  private _gasSupplyEmergencyData: GasSupplyEmergencyData | undefined =
    undefined;

  public get gasSupplyEmergencyData(): GasSupplyEmergencyData | undefined {
    return this._gasSupplyEmergencyData;
  }

  public set gasSupplyEmergencyData(
    gasSupplyEmergencyData: GasSupplyEmergencyData | undefined
  ) {
    this._gasSupplyEmergencyDataSubject$.next(gasSupplyEmergencyData);
  }

  public get gasSupplyEmergencyData$(): Observable<
    GasSupplyEmergencyData | undefined
  > {
    return this._gasSupplyEmergencyDataSubject$.asObservable();
  }

  private _isLoading: boolean = true;

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public set isLoading(isLoading: boolean) {
    this._isLoadingSubject$.next(isLoading);
  }

  public get isLoading$(): Observable<boolean> {
    return this._isLoadingSubject$.asObservable();
  }

  public tearDown(): void {
    this._isLoadingSubscription?.unsubscribe();
    this._gasSupplyEmergencyDataSubscription?.unsubscribe();
  }
}
