import { Injectable, OnDestroy } from '@angular/core';
import { GasSupplyEmergencyState } from '../classes/gas-supply-emergency-state.class';
import {
  catchError,
  EMPTY,
  finalize,
  Observable,
  Subject,
  take,
  tap,
} from 'rxjs';
import { GasSupplyEmergencyData } from '../modules/overview/models/gas-supply-emergency-data.model';
import { RlmBackendService } from '@wgi/rlm/common';
import { EnvironmentService } from '../../main/services/environment.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GasSupplyEmergencyStateService implements OnDestroy {
  private readonly _ngOnDestroySubject$: Subject<void> = new Subject<void>();
  private readonly _gasSupplyEmergencyState: GasSupplyEmergencyState;

  constructor(
    private readonly _rlmBackendService: RlmBackendService,
    private readonly _environmentService: EnvironmentService
  ) {
    this._gasSupplyEmergencyState = new GasSupplyEmergencyState(
      this._ngOnDestroySubject$
    );
  }

  public get isLoading(): boolean {
    return this._gasSupplyEmergencyState.isLoading;
  }

  public set isLoading(isLoading: boolean) {
    this._gasSupplyEmergencyState.isLoading = isLoading;
  }

  public get isLoading$(): Observable<boolean> {
    return this._gasSupplyEmergencyState.isLoading$;
  }

  public get gasSupplyEmergencyData(): GasSupplyEmergencyData | undefined {
    return this._gasSupplyEmergencyState.gasSupplyEmergencyData;
  }

  public set gasSupplyEmergencyData(
    gasSupplyEmergencyData: GasSupplyEmergencyData | undefined
  ) {
    this._gasSupplyEmergencyState.gasSupplyEmergencyData =
      gasSupplyEmergencyData;
  }

  public get gasSupplyEmergencyData$(): Observable<
    GasSupplyEmergencyData | undefined
  > {
    return this._gasSupplyEmergencyState.gasSupplyEmergencyData$;
  }

  public ngOnDestroy(): void {
    this._ngOnDestroySubject$.next();
    this._ngOnDestroySubject$.complete();
    this._gasSupplyEmergencyState.tearDown();
  }

  public updateGasSupplyEmergencyData(): void {
    this.isLoading = true;

    this._updateGasSupplyEmergencyData$().subscribe();
  }

  private _updateGasSupplyEmergencyData$(): Observable<GasSupplyEmergencyData> {
    return this._rlmBackendService
      .getEntry$<GasSupplyEmergencyData>(
        `${this._environmentService.environment.feEnvironmentBackendUrl}/dashboards/customer/emergency-data`
      )
      .pipe(
        tap(
          (gasSupplyEmergencyData: GasSupplyEmergencyData) =>
            (this.gasSupplyEmergencyData = gasSupplyEmergencyData)
        ),
        catchError(() => {
          return EMPTY;
        }),
        finalize(() => {
          this.isLoading = false;
        }),
        take(1),
        takeUntil(this._ngOnDestroySubject$)
      );
  }
}
